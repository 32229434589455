export const numberMask = {
  mask: Number,
  thousandsSeparator: ',',
  signed: true, // disallow negative
}

export const decimalMask = {
  ...numberMask,
  scale: 2,
  padFractionalZeros: true,
  normalizeZeros: false,
  radix: '.', // fractional delimiter
  mapToRadix: ['.'], // symbols to process as radix
}

export const decimalMask2 = { // used for 2 decimals only
  ...decimalMask,
  maskType: 'dec2',
}
